"use client";

import React from "react";

import { Chip, ChipProps } from "@mui/material";
import Box, { BoxProps } from "@mui/material/Box";
import { styled, decomposeColor, recomposeColor } from "@mui/material/styles";

import { ChipType } from "~/theme/chip";
import { COLOR_BLACK, COLOR_GREYSCALE_BLACK_5 } from "~/theme/colors";

import { TAG_CLASSES } from "./constants";

interface UseStylesProps {
  textColor?: string;
  backgroundColor?: string;
  isDescriptionProvided?: boolean;
}

const setOpacityToColor = (color: string, opacity = 1): string => {
  const {
    type,
    values: [r, g, b, a],
  } = decomposeColor(color);
  return recomposeColor({
    type,
    values: [r, g, b, Number(((a ?? 1) * opacity).toFixed(2))],
  });
};

export const ChipStyled = styled(
  ({
    textColor,
    backgroundColor,
    isDescriptionProvided,
    ...restProps
  }: UseStylesProps & ChipProps) => <Chip {...restProps} />,
)(({ theme, textColor, backgroundColor }) => {
  return {
    [`&.${TAG_CLASSES.chipTag}`]: {
      textTransform: "capitalize",
      [`&.${ChipType.tag}`]: {
        "&, &:focus, &:active": {
          backgroundColor: backgroundColor ?? "unset",
          color: textColor ?? "unset",
        },
        "&:hover": {
          color: textColor ? setOpacityToColor(textColor, 0.75) : "unset",
        },
      },
    },
    [`&.${TAG_CLASSES.expandedTag}`]: {
      padding: theme.spacing(2),
      backgroundColor: backgroundColor ?? COLOR_GREYSCALE_BLACK_5,
      color: textColor ?? COLOR_BLACK,
    },
  };
});

export const TagContainer = styled(
  ({
    textColor,
    backgroundColor,
    isDescriptionProvided,
    ...restProps
  }: UseStylesProps & BoxProps) => <Box {...restProps} />,
)(({ theme, textColor, backgroundColor, isDescriptionProvided }) => {
  return {
    [`&.${TAG_CLASSES.chipTag}`]: {
      textTransform: "capitalize",
      [`&.${ChipType.tag}`]: {
        "&, &:focus, &:active": {
          backgroundColor: backgroundColor ?? "unset",
          color: textColor ?? "unset",
        },
        "&:hover": {
          color: textColor ? setOpacityToColor(textColor, 0.75) : "unset",
        },
      },
    },
    [`&.${TAG_CLASSES.expandedTag}`]: {
      padding: theme.spacing(2),
      backgroundColor: backgroundColor ?? COLOR_GREYSCALE_BLACK_5,
      color: textColor ?? COLOR_BLACK,
    },
    [`&. ${TAG_CLASSES.header}`]: {
      textTransform: "capitalize",
      display: "flex",
      alignItems: "center",
      marginBottom: isDescriptionProvided ? theme.spacing(1) : 0,
      "& > *:not(:last-child)": {
        marginRight: theme.spacing(1.5),
      },
    },
  };
});
