"use client";

import React, { useContext } from "react";

import { ProductTag } from "~/bff/types/ProductTag";
import { Optional } from "~/types/general.types";

export const TagListContext = React.createContext<Optional<ProductTag[]>>(undefined);

export const useTagListContext = (): ProductTag[] => {
  return useContext(TagListContext) ?? [];
};
