import { ReactElement } from "react";

export enum TagType {
  /** TODO: either:
   * 1) once it fixed on cms, change according to https://team-1626860771808.atlassian.net/wiki/spaces/OSCAR/pages/83263862/Global+List+of+Service+Keys+Product+Tags
   * 2) fix this doc, if this is a proper key
   **/
  primarkCares = "primarkCares",
  clickAndCollectTrialStore = "clickAndCollectTrialStore",
  clickAndCollectExclusive = "ClickAndCollect_exclusive",
  clickAndCollect = "ClickAndCollect",
  sustainableCotton = "sustainableCotton",
  organicCotton = "organicCotton",
  recycledMaterials = "recycledMaterials",
  podcast = "podcast",
  video = "video",
  article = "article",
  coords = "Coords",
  empty = "",
}

export interface TagData {
  type?: TagType;
  title?: string;
  description?: string;
}

export interface TagItemOptions {
  defaultTitle: string;
  className?: string;
  icon?: ReactElement;
  icon24?: ReactElement;
  backgroundColor?: string;
  color?: string;
}
