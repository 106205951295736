import React from "react";

import { ChipType } from "~/theme/chip";
import {
  COLOR_ADDITIONAL_ALABASTER,
  COLOR_BLACK,
  COLOR_PASTEL_AMOUR,
  COLOR_PASTEL_OYSTER_BAY,
  COLOR_PASTEL_WILLOW_BROOK,
  COLOR_PRIMARY,
  COLOR_WHITE,
} from "~/theme/colors";
import { Article16 } from "~/theme/icons/components/article";
import { Mic16 } from "~/theme/icons/components/mic";
import {
  PrimarkCares16,
  PrimarkCares24,
} from "~/theme/icons/components/primark-cares";
import { Video16 } from "~/theme/icons/components/video";

import { TagType, TagItemOptions } from "./types";

export const TagOptions: Record<TagType, TagItemOptions> = {
  [TagType.primarkCares]: {
    defaultTitle: "Primark cares",
    className: ChipType.tag,
    icon: <PrimarkCares16 htmlColor={COLOR_PRIMARY} />,
    icon24: <PrimarkCares24 htmlColor={COLOR_PRIMARY} />,
    backgroundColor: COLOR_ADDITIONAL_ALABASTER,
    color: COLOR_BLACK,
  },
  [TagType.sustainableCotton]: {
    defaultTitle: "Sustainable cotton",
    className: ChipType.tag,
    icon: <PrimarkCares16 />,
    backgroundColor: COLOR_PASTEL_OYSTER_BAY,
    color: COLOR_BLACK,
  },
  [TagType.organicCotton]: {
    defaultTitle: "Organic cotton",
    className: ChipType.tag,
    icon: <PrimarkCares16 />,
    backgroundColor: COLOR_PASTEL_AMOUR,
    color: COLOR_BLACK,
  },
  [TagType.recycledMaterials]: {
    defaultTitle: "Recycled materials",
    className: ChipType.tag,
    icon: <PrimarkCares16 />,
    backgroundColor: COLOR_PASTEL_WILLOW_BROOK,
    color: COLOR_BLACK,
  },
  [TagType.clickAndCollect]: {
    defaultTitle: "Click & collect",
    className: ChipType.tag,
    backgroundColor: COLOR_BLACK,
    color: COLOR_WHITE,
  },
  [TagType.clickAndCollectExclusive]: {
    defaultTitle: "Click & collect exclusive",
    className: ChipType.tag,
    backgroundColor: COLOR_BLACK,
    color: COLOR_WHITE,
  },
  [TagType.clickAndCollectTrialStore]: {
    defaultTitle: "Click + Collect Trial Store",
    className: ChipType.tag,
    backgroundColor: COLOR_BLACK,
    color: COLOR_WHITE,
  },
  [TagType.podcast]: {
    defaultTitle: "Podcast",
    className: ChipType.mediaTag,
    icon: <Mic16 />,
  },
  [TagType.video]: {
    defaultTitle: "Video",
    className: ChipType.mediaTag,
    icon: <Video16 />,
  },
  [TagType.article]: {
    defaultTitle: "Article",
    className: ChipType.mediaTag,
    icon: <Article16 />,
  },
  [TagType.coords]: {
    defaultTitle: "SHOP SET",
    className: ChipType.tag,
    backgroundColor: COLOR_WHITE,
    color: COLOR_BLACK,
  },
  [TagType.empty]: {
    defaultTitle: "SHOP SET",
    className: ChipType.tag,
    backgroundColor: COLOR_WHITE,
    color: COLOR_BLACK,
  },
};

export const TAG_PREFIX = "Tag";

export const TAG_CLASSES = {
  chipTag: `${TAG_PREFIX}-chipTag`,
  expandedTag: `${TAG_PREFIX}-expandedTag`,
  header: `${TAG_PREFIX}-header`,
};
