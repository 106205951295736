import React, { FC, useMemo } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import clsx from "clsx";

import { Markdown } from "~/components/markdown/component";
import { COLOR_PRIMARY_BLACK, COLOR_PRIMARY_WHITE } from "~/theme/colors";

import { TAG_CLASSES, TagOptions } from "./constants";
import { useTagListContext } from "./hooks";
import { ChipStyled, TagContainer } from "./styled";
import { TagData } from "./types";

export interface TagProps extends TagData {
  className?: string;
  variant?: "expandedTag" | "chipTag";
  onClick?: () => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  customHeader?: React.ReactElement;
  textColor?: string;
  backgroundColor?: string;
}

export const Tag: FC<TagProps> = ({
  className,
  variant = "chipTag",
  type,
  title: forcedTitle,
  description: forcedDescription,
  customHeader,
  onClick,
  onKeyDown,
  backgroundColor,
  textColor,
}) => {
  const tagLibrary = useTagListContext();

  const {
    description,
    title = type ? TagOptions?.[type]?.defaultTitle : undefined,
  } = useMemo(() => {
    return tagLibrary.find((item) => item.key === type) ?? {};
  }, [tagLibrary, type]);

  const getTextColorValue = useMemo(() => {
    if (textColor) {
      return textColor === "light" ? COLOR_PRIMARY_WHITE : COLOR_PRIMARY_BLACK;
    }

    return type ? TagOptions?.[type]?.color : undefined;
  }, [textColor, type]);

  const getBackGroundColorValue = useMemo(() => {
    return (
      backgroundColor || (type ? TagOptions?.[type]?.backgroundColor : undefined)
    );
  }, [backgroundColor, type]);

  const tagOptionsByType = type ? TagOptions?.[type] : undefined;

  return variant === "chipTag" ? (
    <ChipStyled
      className={clsx(className, TAG_CLASSES[variant], tagOptionsByType?.className)}
      label={forcedTitle ?? title}
      icon={tagOptionsByType?.icon}
      onClick={onClick}
      onKeyDown={onKeyDown}
      textColor={getTextColorValue}
      backgroundColor={getBackGroundColorValue}
      isDescriptionProvided={Boolean(forcedDescription || description)}
    />
  ) : (
    <TagContainer
      className={clsx(className, TAG_CLASSES[variant], tagOptionsByType?.className)}
      textColor={getTextColorValue}
      backgroundColor={getBackGroundColorValue}
      isDescriptionProvided={Boolean(forcedDescription || description)}
    >
      {customHeader ? (
        customHeader
      ) : (
        <Box className={TAG_CLASSES.header}>
          {tagOptionsByType?.icon24 ?? tagOptionsByType?.icon}
          <Typography>
            <Box component="strong">{forcedTitle ?? title}</Box>
          </Typography>
        </Box>
      )}
      {(forcedDescription || description) && (
        <Markdown text={forcedDescription ?? description ?? ""} />
      )}
    </TagContainer>
  );
};
