import React from "react";

import { IconProps } from "./../types";

import { BaseSVGIcon } from "./base-svg-icon";

export const Article16: React.FC<IconProps> = (props) => {
  return (
    <BaseSVGIcon width="16" height="16" fill="none" viewBox="0 0 16 16" {...props}>
      <path
        d="M2 14H5L14 5L11 2L2 11V14Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 4.5L11.5 7.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </BaseSVGIcon>
  );
};
